import { authHeader, handleResponse } from '@/_helpers';

export const dataService = {
    getAll,getAllUsers,toggleUser
};

function getAll() {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${process.env.REACT_APP_API_URL}/panel_data`, requestOptions).then(handleResponse);
}

function getAllUsers() {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${process.env.REACT_APP_API_URL}/panel_user_data`, requestOptions).then(handleResponse);
}

function toggleUser(usedID,currentStatus) {
    const requestOptions = { 
        method: 'POST', 
        headers: authHeader(), 
        mode: "cors",
        body: JSON.stringify({"_email":usedID,"_status":currentStatus}),
        
    };
    requestOptions["headers"]["content-type"] = "application/json";
    return fetch(`${process.env.REACT_APP_API_URL}/toggle_user`, requestOptions).then(handleResponse);
}
