import React from 'react';

import { dataService, authenticationService } from '@/_services';
import {HomeControlTable} from '@/HomePage';
class HomePage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentUser: authenticationService.currentUserValue,
            contests: []
        };
    }

    componentDidMount() {
        dataService.getAll().then(contests => 
            {
                this.setState({ "contests" :contests.data.data })
            });
    }

    render() {
        // console.log("contests.data.data",contests.data.data)

        const { currentUser, contests } = this.state;

        return (
            <div>
                <HomeControlTable contests={this.state.contests} />
            </div>
        );
    }
}

export { HomePage };