import React, { useState } from 'react'

import InputBase from '@material-ui/core/InputBase'
import { fade, makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import SearchIcon from '@material-ui/icons/Search'
import DatePicker from "@/_components/DatePickerCalendar"; //import the Datepicker Component we setup from DatePickerCallendar.js
import { AiOutlineSync, } from "react-icons/ai";

const useStyles = makeStyles(theme => ({
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  dateFilterTitle: {

  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  startDate: {
    display: "flex",

  }, endDate: {
    display: "flex",
    marginLeft: "10px",

  },
  datepickerGridContainer: {
    display: "flex",

  },

  datePickerSearchWrapper: {
    display: "flex",
    alignItems: "center",
    adding: "1em 0"
  },
  filterParameters: {
    padding: "1em 0",
    "& > *": {
      marginBottom: ".5em",
      whiteSpace: "nowrap",

    },

  }
}))

const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  setFilter,
  dispatch,
  sortKey,
  toggleAllRowsSelected
}) => {
  const classes = useStyles()
  const count = preGlobalFilteredRows.length

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  //called when a user selects filter start-date 
  const handleStartDate = (date) => {
    setStartDate(date);
  }

  //called when a user selects filter end-date 
  const handleEndDate = (date) => {
    setEndDate(date);
  }
  // Auto Rest All Filters
  const autoResetFilter = () => {
    // console.log("resetting")
    setStartDate(null);
    setEndDate(null);
    dispatch({ type: "resetFilters" });
  }

  // Filter table by selected start-date and end-date
  const handleFilterByDate = () => {
    if (startDate && endDate) {
      // console.log("sortKey",sortKey)

      //bracket_submit_time should be dynamic and depends on table so should be a setting from the table
      setFilter(sortKey, [startDate, endDate]);
    }
  }
  // Handles all calls to filter the table <-- attached to onClick event of "apply filter button" -->
  const applyFilter = () => {

    if (startDate && endDate) {
      handleFilterByDate();
    }

    if (!startDate && endDate || startDate && !endDate) {
      window.alert("Please Make sure you select start-date and end-date");
    }
  }
  // Global filter only works with pagination from the first page.
  // This may not be a problem for server side pagination when
  // only the current page is downloaded.

  return (
    <div>
      <div className={classes.datePickerSearchWrapper}>
        <div className={classes.datePickerButtonWrapper}>

          {/** Datepicker and reset Button section */}
          {typeof sortKey != 'undefined' && <div className={classes.filterParameters} id="filterParameters">
            <div className={classes.dateFilterTitle}>Date Filter</div>
            <div className={classes.datePickerWrapper}>
              <div className={classes.datepickerGridContainer} >
                <div className={classes.startDate}>From: <div className="datePickerLabel">{startDate ? startDate.toLocaleDateString("fr-CA") : null}</div>
                  < DatePicker handleDateChange={handleStartDate} date={startDate} />
                </div>
                <div className="verticalLine"></div>
                <div className={classes.endDate}>To: <div className="datePickerLabel">{endDate ? endDate.toLocaleDateString("fr-CA") : null}</div>
                  < DatePicker handleDateChange={handleEndDate} date={endDate} />
                </div>
              </div>
            </div>
            <div className={classes.resetFilter} onClick={autoResetFilter}><span>Reset</span>
              <AiOutlineSync className="resetFilter_icon" />
            </div>
            <div className={classes.applyFilterClass}>
              <button onClick={applyFilter} className="applyFilter-btn" id="applyFilter-btn">  Filter </button>
            </div>
          </div>}


        </div>
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            value={globalFilter || ''}
            onChange={e => {
              setGlobalFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
            }}
            placeholder={`${count} records...`}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ 'aria-label': 'search' }}
          />
        </div>
      </div>
    </div>
  )
}

GlobalFilter.propTypes = {
  preGlobalFilteredRows: PropTypes.array.isRequired,
  globalFilter: PropTypes.string.isRequired,
  setGlobalFilter: PropTypes.func.isRequired,
}

export default GlobalFilter
