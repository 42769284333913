import React from 'react'

import CssBaseline from '@material-ui/core/CssBaseline'
import {ControlTable} from '@/_components'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import moment from 'moment'
import { dataService } from '@/_services';

const UserControlTable = (props) => {
    console.log("props7",props)
    const userIdName = "User ID";
    const userFirstName = "Name";
    const bracketHistoryTitle = "Bracket History";
    const draftHistoryTitle = "Draft History";
    const mmaHistoryTitle = "Mma History";
    const pickemHistoryTitle = "Pickem History";
    const suspendUserTitle = "Suspend User";
    const enabledStatusKey = "enabled_status";
    const [toggleButton, setToggleButton] = React.useState(true)
    const handleChange = (userId,currentStatus,rowIndex) => (event) => {
      // console.log("userId",userId)
      // console.log("currentStatus",currentStatus)
      dataService.toggleUser(userId,currentStatus).then(userEnabled => 
        {
            // console.log("row",rowIndex)
            // console.log("users",userEnabled)
            updateMyData( rowIndex,enabledStatusKey,userEnabled.data)
        });
    }

    const columns = React.useMemo(
        () => [
          {
            Header: userIdName,
            accessor: 'email',
            Footer: userIdName,
            Cell: ({row}) => {
              return row.original.email+" ("+row.original.id+")"
            }
          },
          {
            Header: userFirstName,
            accessor: 'whole_name',
            Footer: userFirstName,
          },
          {
            Header:bracketHistoryTitle ,
            accessor: 'user_brackets',
            Footer: bracketHistoryTitle,
            Cell: ({row}) => <span dangerouslySetInnerHTML={{__html: row.original.user_brackets}} />

          },
          {
            Header: draftHistoryTitle,
            accessor: 'user_drafts',
            Footer: draftHistoryTitle,
            Cell: ({row}) => <span dangerouslySetInnerHTML={{__html: row.original.user_drafts}} />

          },
          {
            Header:mmaHistoryTitle ,
            accessor: 'user_mmas',
            Footer: mmaHistoryTitle,
            Cell: ({row}) => <span dangerouslySetInnerHTML={{__html: row.original.user_mmas}} />

          },
          {
            Header: pickemHistoryTitle,
            accessor: 'user_pickems',
            Footer: pickemHistoryTitle,
            Cell: ({row}) => <span dangerouslySetInnerHTML={{__html: row.original.user_pickems}} />

          },
          {
            Header: suspendUserTitle,
            accessor: enabledStatusKey,
            Footer: suspendUserTitle,
            Cell: ({ value, cell: { row } }) => (
                <div>
                <FormControlLabel control={<Switch checked={(row.values.enabled_status === "1")} onChange={handleChange(row.values.email,parseInt(row.values.enabled_status),row.index)}    />} label={parseInt(row.values.enabled_status)? <span>Active</span> : <span>Inactive</span>} />

                
                
               
                </div>
            ),
          },
          
        ],
        []
      )
  //const data = typeof props.users != 'undefined' ? props.users : [];
//   console.log(" props.data", data)
//   console.log(" props.setData", setData)

  const [data, setData] = React.useState([]);
  React.useEffect(() => {
    dataService.getAllUsers().then(users => 
      {
            // console.log("users",users.data.data)
            setData(users.data.data);
      });
  }, []);
  const [skipPageReset, setSkipPageReset] = React.useState(false)

  // We need to keep the table from resetting the pageIndex when we
  // Update data. So we can keep track of that flag with a ref.

  // When our cell renderer calls updateMyData, we'll use
  // the rowIndex, columnId and new value to update the
  // original data
  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true)
    setData(old =>
      old.map((row, index) => {
        if (index === rowIndex) {
          // console.log("old[rowIndex]",old[rowIndex])
          // console.log("value",value)
          return {
            ...old[rowIndex],
            [columnId]: value,
          }
        }
        return row
      })
    )
  }



  return (
    <div>
      <CssBaseline />
      <ControlTable
        columns={columns}
        data={data}
        setData={setData}
        updateMyData={updateMyData}
        skipPageReset={skipPageReset}
        fileName="UserDBExport"

      />
    </div>
  )
}

export  {UserControlTable}
