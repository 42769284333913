import React from 'react'

import CssBaseline from '@material-ui/core/CssBaseline'
import {ControlTable} from '@/_components'
import moment from 'moment'

const HomeControlTable = (props) => {
    console.log("props7",props)

  
    const columns = React.useMemo(
        () => [
          {
            Header: 'POOL ID',
            accessor: 'pool_id',
            Footer: '',           
              Cell:  row  => {
                return row.cell.row.original.bracket_sport+row.cell.row.original.bracket_id
              },

          },
          {
            Header: 'VIG PAY DAY',
            accessor: 'bracket_submit_time',
            Footer: ' ',
            filter: 'dateFilter'
          },
          {
            Header: 'NAME OF POOL',
            accessor: 'bracket_name',
            Footer: ' ',

          },
          {
            Header: 'POOL SPORT  ',
            accessor: 'bracket_sport',
            Footer: ' ',
          },
          {
            Header: 'ACTUAL POOLS FILLED',
            accessor: 'actual_filled',
            Footer: info => {
                // Only calculate total visits if rows change
                const pageRows =  info.page.map(r => r.original);

                const total = React.useMemo(
                  () =>
                    pageRows.reduce((sum, row) => parseInt(row.actual_filled) + parseInt(sum), 0),
                  [pageRows]
                )
  
                return <>{total}</>
              },

          },
          {
            Header: 'ACTUAL TOTAL USERS ENTERED from all Pools',
            accessor: 'total_users_entered',
            Footer: info => {
                // Only calculate total visits if rows change
                const pageRows =  info.page.map(r => r.original);
                // console.log("info",pageRows);

                const total = React.useMemo(
                  () =>
                    pageRows.reduce((sum, row) => parseInt(row.total_users_entered) + parseInt(sum), 0),
                  [pageRows]
                )
  
                return <>{total}</>
              },

          },
          {
            Header: 'Pool WAGER',
            accessor: 'bracket_fee',
            Footer: ' ',

          },
          {
            Header: 'HANDLE',
            accessor: 'handle',
            Footer: info => {
                // Only calculate total visits if rows change

                const pageRows =  info.page.map(r => r.original);

                const total = React.useMemo(
                  () =>
                    pageRows.reduce((sum, row) => parseInt(row.handle) + parseInt(sum), 0),
                  [pageRows]
                )
  
                return <>{(total).toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                })}</>              },
              Cell:  row  => {
                return "$"+row.cell.row.original.handle
              },

          },
          {
            Header: 'VIG earned per wager',
            accessor: 'vig',
            Footer: ' ',

          },
          {
            Header: 'Total Vig Earned',
            accessor: 'actual_vig_earned',
            Footer: info => {
                // Only calculate total visits if rows change
                const pageRows =  info.page.map(r => r.original);

                const total = React.useMemo(
                  () =>
                    pageRows.reduce((sum, row) => parseInt(row.actual_vig_earned) + parseInt(sum), 0),
                  [pageRows]
                )
  
                return <>{(total).toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                })}</>              },
              Cell:  row  => {
                return "$"+row.cell.row.original.actual_vig_earned
              },

          },
          {
            Header: 'SPAWN POOL MAX',
            accessor: 'max_spawns',
            Footer: ' ',

          },
          {
            Header: 'MAX VIG POTENTIAL REVENUE ',
            accessor: 'max_vig_potential_revenue',
            Footer: info => {
                // Only calculate total visits if rows change
                const pageRows =  info.page.map(r => r.original);

                const total = React.useMemo(
                  () =>
                    pageRows.reduce((sum, row) => parseInt(row.max_vig_potential_revenue) + parseInt(sum), 0),
                  [pageRows]
                )
  
                return <>{(total).toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                })}</>              },
              Cell:  row  => {
                return "$"+row.cell.row.original.max_vig_potential_revenue
              },

            
          },
        ],
        []
      )
// console.log(" props.contests", props.contests)
  const data = typeof props.contests != 'undefined' ? props.contests : [];
//   const setData = typeof props.contests != 'undefined' ? props.contests : [];
//   console.log(" props.data", data)
//   console.log(" props.setData", setData)

  const [skipPageReset, setSkipPageReset] = React.useState(false)

  // We need to keep the table from resetting the pageIndex when we
  // Update data. So we can keep track of that flag with a ref.

  // When our cell renderer calls updateMyData, we'll use
  // the rowIndex, columnId and new value to update the
  // original data
  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true)
    // setData(old =>
    //   old.map((row, index) => {
    //     if (index === rowIndex) {
    //       return {
    //         ...old[rowIndex],
    //         [columnId]: value,
    //       }
    //     }
    //     return row
    //   })
    // )
  }

  return (
    <div>
      <CssBaseline />
      <ControlTable
        columns={columns}
        data={data}
        // setData={setData}
        // updateMyData={updateMyData}
        skipPageReset={skipPageReset}
        fileName="ContestDBExport"
        sortKey="bracket_submit_time"
        
      />
    </div>
  )
}

export  {HomeControlTable}
