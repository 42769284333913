import { BehaviorSubject } from 'rxjs';
import { handleResponse } from '@/_helpers';
import base64 from 'base-64';

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));
export const authenticationService = {
    login,
    logout,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue () { return currentUserSubject.value }
};

function login(username, password) {
    console.log('{process.env.REACT_APP_BASIC_USER',process.env.REACT_APP_BASIC_USER);
     //   console.log('{process.env.REACT_APP_BASIC_USER',process.env.REACT_APP_BASIC_PASS);

    const requestOptions = {
        method: 'POST',
        referrerPolicy: 'no-referrer-when-downgrade',
        headers: {
        'Authorization': 'Basic '+base64.encode(`${process.env.REACT_APP_BASIC_USER}:${process.env.REACT_APP_BASIC_PASS}`), 
        'Content-Type': 'application/json'
          }, 
    //recaptcha can be any value so need fixing on server side
      body: JSON.stringify({ "_password": password ,"_email":username,"_recaptcha":"03AGdBq24ndQeX66kGhPomKHj2H1ssELbkIX8fLGviSRykStUaLUa28CmlRWHQ"})
    };
    return fetch(`${process.env.REACT_APP_API_URL}/login`,  requestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // console.log("user",user)
            localStorage.setItem('currentUser', JSON.stringify(user));
            currentUserSubject.next(user);
    
            return user;
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    currentUserSubject.next(null);
}