import React from 'react'

import Checkbox from '@material-ui/core/Checkbox'
import MaUTable from '@material-ui/core/Table'
import PropTypes from 'prop-types'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableFooter from '@material-ui/core/TableFooter'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TablePaginationActions from './TablePaginationActions'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import TableToolbar from './TableToolbar'
import {
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
  useFilters,
} from 'react-table'

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
        <Checkbox ref={resolvedRef} {...rest} />
      </>
    )
  }
)

const inputStyle = {
  padding: 0,
  margin: 0,
  border: 0,
  background: 'transparent',
}

// Set our editable cell renderer as the default Cell renderer
const defaultColumn = {
  // Cell: EditableCell,
}

  /**
   * @desc get table data as json
   * @param data
   * @param columns
   */
   const getTableDataForExport = (data: any[], columns: any[]) => data?.map((record: any) => columns
   .reduce((recordToDownload, column) => (
     { ...recordToDownload, [column.Header]: record[column.accessor] }
   ), {}));
   
   /**
    * @desc make csv from given data
    * @param rows
    * @param filename
    */
   const makeCsv = async (rows: any[], filename: string, selected : any[]) => {
  
    // console.log("this",selectedMap)

    // console.log("selected",selected[0])
    // return false;

    if(selected.length > 0 ) {
      rows = selected;

    }
     const separator: string = ',';
     const keys: string[] = Object.keys(rows[0]);
   
   const csvContent = `${keys.join(separator)}\n${
     rows.map((row) => keys.map((k) => {
      //  console.log("k",k)
      //  console.log(" row[k] ", row[k] )
       if(k.indexOf("user_") > -1) {
         if(row[k] != null) {
         row[k] = row[k].replace(/<br\s*\/?>/gi,"\r\n");

         }
       }
       //\r\n
       let cell = row[k] === null || row[k] === undefined ? '' : row[k];
   
       cell = cell instanceof Date
         ? cell.toLocaleString()
         : cell.toString().replace(/"/g, '""');
   
       if (cell.search(/("|,|\n)/g) >= 0) {
         cell = `"${cell}"`;
       }
       return cell;
     }).join(separator)).join('\n')}`;
  //  return false;
   const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
     if (navigator.msSaveBlob) { // In case of IE 10+
       navigator.msSaveBlob(blob, filename);
     } else {
       const link = document.createElement('a');
       if (link.download !== undefined) {
         // Browsers that support HTML5 download attribute
         const url = URL.createObjectURL(blob);
         link.setAttribute('href', url);
         link.setAttribute('download', filename);
         link.style.visibility = 'hidden';
         document.body.appendChild(link);
         link.click();
         document.body.removeChild(link);
       }
     }
   };
   function showData(rows2) {
    console.log("rows2",rows2)
     
   }

export const ControlTable = ({
  columns,
  data,
  setData,
  updateMyData,
  skipPageReset,
  fileName,
  sortKey,

}) => {

  const filterTypes = React.useMemo(() => ({
      dateFilter: (rows, id, filterValue) => {
          return rows = rows.filter(row => {
              // console.log("ow.values.date",Date(row.values[id]))
              // console.log("id[0]",id)
              // console.log("filterValue[0]",filterValue)
              // console.log("row[0]",row)
              return new Date(row.values[id]) >= filterValue[0] && new Date(row.values[id]) <= filterValue[1];
          });
      },
  }),
      []
  )
  const [selectedRows, setSelectedData] = React.useState([]);
  const onSelectedRows = rows => {
    const mappedRows = rows.map(r => r.original);
    setSelectedData([...mappedRows]);
  };

  const {
    getTableProps,
    headerGroups,
    footerGroups,
    prepareRow,
    selectedFlatRows,
    toggleAllRowsSelected,
    page,
    gotoPage,
    setPageSize,
    preGlobalFilteredRows,
    rows,
    setGlobalFilter,
    setFilter,
    dispatch,
    state: { pageIndex, pageSize, selectedRowIds, globalFilter
    },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      autoResetPage: !skipPageReset,
      // updateMyData isn't part of the API, but
      // anything we put into these options will
      // automatically be available on the instance.
      // That way we can call this function from our
      // cell renderer!
      // updateMyData,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    hooks => {
      hooks.allColumns.push(columns => [
        // Let's make a column for selection
        {
          id: 'selection',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox.  Pagination is a problem since this will select all
          // rows even though not all rows are on the current page.  The solution should
          // be server side pagination.  For one, the clients should not download all
          // rows in most cases.  The client should only download data for the current page.
          // In that case, getToggleAllRowsSelectedProps works fine.
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ])
    }
  )
  React.useEffect(() => {
    onSelectedRows(selectedFlatRows);
  }, [selectedFlatRows]);


  const handleChangePage = (event, newPage) => {
    gotoPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setPageSize(Number(event.target.value))
  }

  const removeByIndexs = (array, indexs) =>
    array.filter((_, i) => !indexs.includes(i))



  // Render the UI for your table
  return (
    <div>    
    <div>    
      <div>

                <p>&nbsp;</p>
               
              <button type="button" style={{ marginRight: '10px' }} onClick={() => makeCsv(getTableDataForExport(rows.map(r => r.original), columns), `${fileName}.csv`,selectedRows)}>
                Download table data CSV
              </button>
              <button  onClick={() => toggleAllRowsSelected(false)}>
      Clear Selection
              </button>

</div>
    <TableContainer>
      <TableToolbar
        numSelected={Object.keys(selectedRowIds).length}

        preGlobalFilteredRows={preGlobalFilteredRows}
        setGlobalFilter={setGlobalFilter}
        setFilter={setFilter}
        dispatch={dispatch}
        sortKey={sortKey}
        globalFilter={globalFilter}
      />
      <div style={{ maxHeight: '100vh', overflow: 'auto' }}>
    
      <MaUTable   {...getTableProps()}>

        <TableHead>
          {headerGroups.map(headerGroup => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <TableCell 
                  {...(column.id === 'selection'
                    ? column.getHeaderProps()
                    : column.getHeaderProps(column.getSortByToggleProps()))}
                >
                  {column.render('Header')}
                  {column.id !== 'selection' ? (
                    <TableSortLabel
                      active={column.isSorted}
                      // react-table has a unsorted state which is not treated here
                      direction={column.isSortedDesc ? 'desc' : 'asc'}
                    />
                  ) : null}
                </TableCell>
              ))}
            </TableRow>
            
           
          ))}
          
        </TableHead>

        <TableBody>

          {page.map((row, i) => {
            prepareRow(row)
            return (
              <TableRow {...row.getRowProps()}>
    
                {row.cells.map(cell => {
                  return (
                    <TableCell {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </TableCell>

                  )
                })}
              </TableRow>
            )
          })}

        </TableBody>

        <TableFooter>
  {footerGroups.map(group => (
    <tr {...group.getFooterGroupProps()}>
      {group.headers.map(column => (
        <td {...column.getFooterProps()}>{column.render('Footer')}</td>
      ))}
    </tr>
  ))}

  </TableFooter>
      </MaUTable>
      </div>
      

    </TableContainer>
    </div>
          <MaUTable>
          <TablePagination
              rowsPerPageOptions={[
                5,
                10,
                25,
                { label: 'All', value: data.length },
              ]}
              colSpan={1000}
              count={rows.length}
              rowsPerPage={pageSize}
              page={pageIndex}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
  
 </MaUTable>
</div>
  )
}

ControlTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  updateMyData: PropTypes.func.isRequired,
  setData: PropTypes.func.isRequired,
  skipPageReset: PropTypes.bool.isRequired,
}

