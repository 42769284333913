import React from 'react';

import {  authenticationService } from '@/_services';
import {UserControlTable} from '@/UserPage';
class UserPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentUser: authenticationService.currentUserValue,
            users: []
        };
    }

    componentDidMount() {

    }

    render() {
        // console.log("contests.data.data",contests.data.data)

        const { currentUser, contests } = this.state;

        return (
            <div>
                <UserControlTable />
            </div>
        );
    }
}

export { UserPage };